<template>
    <v-list dense light :color="bgColor" class="pa-0">
      <v-list-item>
        <v-list-item-avatar color="blue darken-1" size="32">
          <img v-if="userInfo.userImageUrl" :src="userInfo.userImageUrl">
          <span v-else class="text-caption white--text pl-1">{{iconLabel}}</span>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="content-text text-body-2">
            {{userInfo.userName}}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: ['bgColor'],
  computed: {
    ...mapGetters(['userInfo']),

    iconLabel() {
      if(this.userInfo) {
        const firstName = this.userInfo.firstName;
        const lastName = this.userInfo.lastName;
        return firstName ? firstName.slice(0, 1) + lastName.slice(0, 1) : lastName.slice(0, 2)
      } else {
        return null;
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.content-text {
  color: rgba(0,0,0,.8);
}
</style>
