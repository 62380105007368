<template>
  <div>
    <v-card outlined>
      <v-card-text class="pa-4 px-10 py-10">
        <image-select-dialog
          v-model="dialog"
          :src="project.projectIconUrl"
          @change-url="project.projectIconUrl = $event"
          caption="アイコンを変更"
          :func="1"
        ></image-select-dialog>

        <v-row>
          <v-col cols="12" md="2">
            <v-hover v-slot="{ hover }">
              <v-card
                v-if="project.projectIconUrl"
                max-height="150"
                max-width="150"
                class="mx-auto"
              >
                <v-img
                  :src="project.projectIconUrl"
                  max-height="150"
                  max-width="150"
                  aspect-ratio="1.0"
                ></v-img>
                <v-btn
                  class="project-entry-form__icon-btn"
                  :class="{ 'project-entry-form__icon-btn--hover': hover }"
                  x-small
                  fab
                  color="primary"
                  @click="dialog = true"
                >
                  <v-icon color="white">mdi-pencil</v-icon>
                </v-btn>
              </v-card>
              <v-card v-else max-height="150" max-width="150" class="mx-auto">
                <v-responsive :aspect-ratio="1 / 1">
                  <v-overlay absolute z-index="4">
                    <v-btn v-show="!readonly" text color="white" @click="dialog = true">
                      アイコンを追加
                    </v-btn>
                  </v-overlay>
                </v-responsive>
              </v-card>
            </v-hover>
          </v-col>
          <v-col>
            <v-row>
              <v-col cols="12" md="10" class="pb-0">
                <v-text-field
                  v-model="project.salesforceUrl"
                  label="Salesforce顧客連携"
                  hint="Salesforce顧客画面のURLを入力してください"
                  persistent-hint
                  outlined
                  :readonly="readonly"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="pt-md-4">
                <div class="project-entry-form__form-button-wrap">
                  <v-btn
                    @click="clickConnectButton"
                    color="#0761B4"
                    class="white--text"
                    block
                    depressed
                    large
                    rounded
                    >連携</v-btn
                  >
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="5" align-self="center">
                <v-text-field
                  v-model="project.clientName"
                  label="顧客名"
                  outlined
                  hide-details
                  :readonly="readonly"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5" align-self="center">
                <v-text-field
                  v-model="project.businessName"
                  label="屋号名"
                  outlined
                  hide-details
                  :readonly="readonly"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="10">
                <v-text-field
                  v-model="project.projectName"
                  label="プロジェクト"
                  outlined
                  hide-details
                  :readonly="readonly"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="10">
                <v-combobox
                  class="affiliatedUser"
                  v-model="project.affiliatedUser"
                  item-text="userName"
                  label="PJ関係者"
                  outlined
                  multiple
                  small-chips
                  deletable-chips
                  hide-details
                  hide-no-data
                  readonly
                ></v-combobox>
              </v-col>
              <v-col cols="12" md="2">
                <div class="project-entry-form__form-button-wrap">
                  <v-btn
                    @click="openUserSelectDialog()"
                    color="#0761B4"
                    class="white--text"
                    block
                    depressed
                    large
                    rounded
                    :disabled="readonly"
                    >選択</v-btn
                  >
                </div>
              </v-col>
            </v-row>

            <v-row class="mx-0">
              <v-col cols="12" md="10" class="row px-0">
                <v-col cols="12" md="4">
                  <v-select
                    v-model="project.status"
                    :items="MasterData.statuses"
                    item-text="status"
                    item-value="id"
                    label="ステータス"
                    outlined
                    hide-details="auto"
                    :readonly="readonly"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="project.industry"
                    :items="MasterData.industries"
                    label="業界"
                    item-text="industry"
                    item-value="industry"
                    outlined
                    hide-details
                    :readonly="readonly"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="4" class="pr-md-0">
                  <v-select
                    v-model="project.businessPhase"
                    :items="MasterData.businessPhases"
                    label="事業フェーズ"
                    item-text="businessPhase"
                    item-value="businessPhase"
                    outlined
                    hide-details="auto"
                    :readonly="readonly"
                  >
                  </v-select>
                </v-col>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="10">
                <v-select
                  v-model="project.issue"
                  :items="MasterData.issues"
                  label="課題分類"
                  item-text="issue"
                  item-value="issue"
                  return-object
                  outlined
                  multiple
                  small-chips
                  deletable-chips
                  hide-details
                  :readonly="readonly"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="5">
                <v-text-field
                  v-model="project.siteUrl"
                  label="対象URL"
                  outlined
                  hide-details
                  :readonly="readonly"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field
                  v-model="project.analyticsUrl"
                  label="数値データURL"
                  outlined
                  hide-details
                  :readonly="readonly"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- SNSアカウント -->
            <v-row class="pt-1 pb-0">
              <v-col cols="10" class="d-flex align-center">
                <div class="black--text text-caption me-5">外部リンク</div>
              </v-col>
              <v-col cols="12" md="10" class="pt-0">
                <v-card outlined class="pt-3">
                  <v-card-text>
                    <v-row v-for="(item, idx) in snsAccount" :key="idx" class="align-center mb-3 mb-md-0">
                      <v-col cols="11" md="3" class="py-2 order-md-0">
                        <v-combobox
                          v-model="item.serviceName"
                          :items="MasterData.snsAccount"
                          label="サービス名"
                          outlined
                          hide-details
                          dense
                          :readonly="readonly"
                        ></v-combobox>
                      </v-col>
                      <v-col cols="1" v-show="!readonly" class="px-0  order-md-3">
                        <v-btn icon @click="snsAccount.splice(idx, 1)">
                          <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="12" md="8" class="py-2 order-md-2">
                        <v-text-field
                          v-model="item.accountUrl"
                          label="アカウントURL"
                          outlined
                          hide-details
                          dense
                          :readonly="readonly"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <div class="text-center mt-4" v-show="!readonly">
                      <div>
                        <common-add-btn
                          color="btPrimary"
                          @click="addSnsAccount()"
                        />
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="ma-0 pa-0">
          <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
          <v-col align="center" sm="11" md="10" class="pr-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="12">
                <salesforce-view
                  ref="sfv"
                ></salesforce-view>
              </v-col>
            </v-row>
          </v-col>
          <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
        </v-row>
        <v-row class="project-entry-form__entry-button-row" v-show="!readonly">
          <v-col></v-col>
          <v-col align="center">
            <v-btn
              @click="clickEntryButton()"
              color="btAccent"
              class="white--text"
              block
              depressed
              x-large
              rounded
              >{{ entryButtonLabel }}</v-btn
            >
          </v-col>
          <v-col></v-col>
        </v-row>
      </v-card-text>

      <user-select-dialog
        v-model="userSelectDialog"
        :affiliatedUsers="project.affiliatedUser"
        @selected="setSelectedUser($event)"
        @canceled="closeUserSelectDialog()"
      >
      </user-select-dialog>
    </v-card>
    <v-overlay :absolute="absolute" :value="overlay">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import ImageSelectDialog from "../atoms/ImageSelectDialog.vue";
import ProjectProgress from "../molecules/ProjectProgress.vue";
import SalesforceView from "../organisms/SalesforceView.vue";
import UserSelectDialog from "../organisms/UserSelectDialog.vue";
import CommonAddBtn from "../atoms/CommonAddBtn.vue";
import MasterData from '../../../utils/MasterData'
import {RepositoryFactory} from '../../../api/RepositoryFactory'

export default {
  props: ["value"],
  components: {
    ImageSelectDialog,
    ProjectProgress,
    SalesforceView,
    UserSelectDialog,
    CommonAddBtn,
  },
  data() {
    return {
      project: {
        status: null,
        projectName: null,
        projectIconUrl: null,
        clientName: null,
        businessName: null,
        industry: null,
        businessPhase: null,
        siteUrl: null,
        analyticsUrl: null,
        issue: [],
        affiliatedUser: [],
        snsAccount: [],
        salesforceUrl: null,
        salesforceClientName: null,
        salesforceTradeName: null,
        salesforceCustomerCode: null,
        salesforceRegion: null,
        salesforceAddress1: null,
        salesforceAddress2: null,
        salesforceWebsite: null,
        salesforceSales: null,
        salesforceEmployeeNumType: null,
        salesforceBtIndustryClassification: null,
        salesforceBusinessCategoryName1: null,
        salesforceBusinessCategoryName2: null,
        salesforceLbcStory: null,
        salesforceLbcBestIndustry: null,
        brandStatus: "notInput",
        flameworkStatus: "notInput",
        userinsightStatus: "notInput",
        designStatus: "notInput",
        planningStatus: "notInput",
      },
      absolute: true,
      overlay: false,
      dialog: false,
      userSelectDialog: false,
      MasterData: {},

      snsAccount: {},
    };
  },
  created() {
    if (this.isUpdateMode) {
      this.project = this.value;
    } else {
      this.project.affiliatedUser.push( this.userInfo );
    }
    this.snsAccount = this.project.snsAccount;
  },
  computed: {
    ...mapGetters(["userInfo", "hasWritePermission"]),
    entryButtonLabel() {
      return this.isUpdateMode ? "更新" : "登録"
    },

    isUpdateMode() {
      return !!this.value;
    },

    readonly() {
      // 新規作成の場合は必ず書き込みモード
      if(!this.isUpdateMode) return false

      // 編集の場合
      // 閲覧モードで開いている場合、プロジェクトへの書き込み権限がない場合はreadonly
      if(this.$route.query?.mode == 'readonly' || !this.hasWritePermission) return true
      else return false
    }
  },
  methods: {
    clickEntryButton: function () {
      // snsアカウント未入力除外
      this.project.snsAccount = this.snsAccount.filter(i => i.serviceName || i.accountUrl); 

      // validation
      this.$emit("entry", this.project);
    },
    clickConnectButton: async function () {
      if ( !this.project.salesforceUrl ) { return; }
      this.overlay = true;
      this.updateSalesforceData( null );
      const SalesforceRepository = RepositoryFactory.get( 'salesforce' );
      const response = await SalesforceRepository.getSalesforce( { salesforceUrl: this.project.salesforceUrl } );
      if (response.data.result && response.data.resultCode === '0000') {
        let account = response.data.data.salesforce;
        for( let key in account ) {
          if ( account.hasOwnProperty( key ) && this.project.hasOwnProperty( key ) ) {
            this.project[key] = account[key];
          }
        }
        this.$refs.sfv.update(account);
        this.project.clientName = account.salesforceClientName;
        this.project.businessName = account.salesforceTradeName;
      } else if ( response.data.result == false ) {
        this.$store.commit('setToast', {msg: '該当データが見つかりませんでした。\nURLを確認ください', color: 'red', timeout: 4000});
      }
      this.overlay = false;
    },
    openUserSelectDialog: function () {
      this.userSelectDialog = true;
    },
    closeUserSelectDialog: function () {
      this.userSelectDialog = false;
    },
    setSelectedUser: function (val) {
      this.project.affiliatedUser = val;
      this.closeUserSelectDialog();
    },
    updateSalesforceData( obj ) {
      const keys = [
        "salesforceClientName",
        "salesforceTradeName",
        "salesforceCustomerCode",
        "salesforceRegion",
        "salesforceAddress1",
        "salesforceAddress2",
        "salesforceWebsite",
        "salesforceSales",
        "salesforceEmployeeNumType",
        "salesforceBtIndustryClassification",
        "salesforceBusinessCategoryName1",
        "salesforceBusinessCategoryName2",
        "salesforceLbcStory",
        "salesforceLbcBestIndustry",
      ];
      const result = {};
      for( let i = 0; i < keys.length; i++ ) {
        let key = keys[i];
        if ( obj && obj.hasOwnProperty( key ) ) {
          result[key] = obj[key];
        } else {
          result[key] = null;
        }
      }
      this.$refs.sfv.update( result );
    },
    addSnsAccount() { 
      this.snsAccount.push({serviceName: null, accountUrl: null})
    }
  },
  watch: {
    value(val) {
      this.project = val;
      this.updateSalesforceData( this.project );
    },
    snsAccount(val) {
      if(!val) val = [];
      if(!val.length) val.push({serviceName: null, accountUrl: null})
      this.snsAccount = val;
      this.project.snsAccount = val;
    }
  },
  mounted() {
    this.MasterData = MasterData
  }
};
</script>
<style lang="scss" scoped>
.project-entry-form {
  &__icon-btn {
    position: absolute;
    bottom: 5px;
    right: 5px;
    opacity: 0;

    &--hover {
      opacity: 1;
    }
  }

  &__entry-button-row {
    margin-bottom: 30px;
  }
}
.affiliatedUser ::v-deep .v-input__icon--append {
  display: none;
}
</style>
