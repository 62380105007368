<template>
  <div>
    <v-row class="common-header__row mt-1 mb-1">
      <v-col class="py-1 px-0"><v-img alt="Branding Technology" max-width="345" max-height="150" src="../../../assets/images/btlogo.png"></v-img></v-col>
      <v-col align="right" class="common-header__login-user py-1 px-0">
        <user-information bgColor="rgb(0,0,0,0)"></user-information>
        <v-btn color="#085fc2" text plain class="ml-1" :to="{ name: 'TopPage' }" :disabled="$route.name == 'TopPage'">
          <v-icon left>mdi-home</v-icon>
          <span class="text-caption font-weight-bold">Topに戻る</span>
        </v-btn>
        <v-btn color="#085fc2" text plain class="ml-1" @click="logout()">
          <v-icon left>mdi-logout</v-icon>
          <span class="text-caption font-weight-bold">ログアウト</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-divider class="common-header__row mb-6" color="#547cbc"></v-divider>
  </div>
</template>

<script>
import UserInformation from '../molecules/UserInformation'
export default {
  components: {
    UserInformation 
  },
  methods: {
    logout() {
      localStorage.removeItem('token')
      localStorage.removeItem('tokenExpirationDate')
      this.$store.commit('accessToken', null)
      this.$router.push({ name: 'LoginPage' })
    },
  },
}
</script>

<style lang="scss" scoped>
  .common-header{

    &__row {
      max-width: 1500px;
      margin-left: auto;
      margin-right: auto;
    }

    &__login-user {
      display: flex;
      align-items: center;
      flex-grow: unset;
    }
  }
</style>
