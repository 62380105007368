<template>
  <v-card v-if="view" class="salesforce pa-sm-2 py-md-6 px-md-3">
    <v-card-title class="pt-0 pb-3 text-body-1">Salesforce顧客情報</v-card-title>
    <v-card-text class="pt-3">
      <v-row>
        <v-col>
          <v-text-field class="salesforce__form-field" v-model="account.salesforceClientName" label="顧客名" outlined readonly hide-details></v-text-field>
        </v-col>
        <v-col>
          <v-text-field class="salesforce__form-field" v-model="account.salesforceTradeName" label="屋号名" outlined readonly hide-details></v-text-field>
        </v-col>
        <v-col>
          <v-text-field class="salesforce__form-field" v-model="account.salesforceCustomerCode" label="顧客コード" outlined readonly hide-details></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field class="salesforce__form-field" v-model="account.salesforceRegion" label="地域" outlined readonly hide-details></v-text-field>
        </v-col>
        <v-col>
          <v-text-field class="salesforce__form-field" v-model="account.salesforceAddress1" label="住所１" outlined readonly hide-details></v-text-field>
        </v-col>
        <v-col>
          <v-text-field class="salesforce__form-field" v-model="account.salesforceAddress2" label="住所２" outlined readonly hide-details></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field class="salesforce__form-field" v-model="account.salesforceWebsite" label="ホームページURL" outlined readonly hide-details></v-text-field>
        </v-col>
        <v-col>
          <v-text-field class="salesforce__form-field" v-model="account.salesforceSales" label="売上高" outlined readonly hide-details></v-text-field>
        </v-col>
        <v-col>
          <v-text-field class="salesforce__form-field" v-model="account.salesforceEmployeeNumType" label="従業員数区分" outlined readonly hide-details></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field class="salesforce__form-field" v-model="account.salesforceBtIndustryClassification" label="業種分類" outlined readonly hide-details></v-text-field>
        </v-col>
        <v-col>
          <v-text-field class="salesforce__form-field" v-model="account.salesforceBusinessCategoryName1" label="業態１" outlined readonly hide-details></v-text-field>
        </v-col>
        <v-col>
          <v-text-field class="salesforce__form-field" v-model="account.salesforceBusinessCategoryName2" label="業態２" outlined readonly hide-details></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field class="salesforce__form-field" v-model="account.salesforceLbcStory" label="ストーリー" outlined readonly hide-details></v-text-field>
        </v-col>
        <v-col>
          <v-text-field class="salesforce__form-field" v-model="account.salesforceLbcBestIndustry" label="良業種" outlined readonly hide-details></v-text-field>
        </v-col>
        <v-col>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      account: null,
      view: false
    }
  },
  methods: {
    update( value ) {
      this.account = value;
	  this.view = true;
	}
  }
}
</script>
<style lang="scss" scoped>
  .salesforce {
    background-color: #f3faff;

    &__form-field {
      background-color: #FFF;
    }
  }
</style>
