<template>
  <div>
    <v-dialog v-model="userSelectDialog" max-width="600" persistent>
      <v-card class="pb-2" elevation="0" width="600">
        <v-card-title>PJ関係者</v-card-title>
        <v-card-text class="pb-1">
          <v-row>
            <v-col cols="10" align-self="center" class="pb-0">
              <v-select
                v-model="searchDepartment"
                prepend-inner-icon="mdi-magnify"
                label="所属"
                :items="departmentsList"
                item-text="departmentName"
                item-value="id"
                outlined
                multiple
                small-chips
                deletable-chips
                hide-details
                clearable
              ></v-select>
            </v-col>
            <v-col cols="10" align-self="center">
              <v-text-field
                v-model="searchUserName"
                prepend-inner-icon="mdi-magnify"
                label="ユーザー名"
                outlined
                hide-details
                clearable
              >
              </v-text-field>
            </v-col>
            <v-col cols="2" align-self="center" class="pa-0 pb-1">
              <v-btn color="primary" depressed @click="search()">検索</v-btn>
            </v-col>
          </v-row>

          <v-data-table
            v-model="selected"
            dense
            show-select
            height="352"
            :headers="tableHeaders"
            :items="usersList"
            :items-per-page="10"
            :calculate-widths="true"
            :fixed-header="true"
            :disable-pagination="true"
            :hide-default-footer="true"
            item-key="userId"
            class="elevation-1 mt-6 mb-3"
          ></v-data-table>
        </v-card-text>

        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="pa-3 mr-3 text-body-1"
            width="126"
            color="primary"
            large
            depressed
            @click="selectOK()"
          >
            選択する
          </v-btn>
          <v-btn
            class="pa-3 text-body-1"
            width="126"
            color="grey lighten-2"
            large
            depressed
            @click="selectCancel()"
          >
            キャンセル
          </v-btn>
        </v-card-actions>
      </v-card>
    <v-overlay
      :absolute="absolute"
      :value="overlay"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import {RepositoryFactory} from '../../../api/RepositoryFactory'

export default {
  props: ["value", "affiliatedUsers"],
  components: {},
  data() {
    return {
      searchDepartment: [],
      searchUserName: "",
      searchResult: [],
      selected: [],
      tableHeaders: [
        {
          text: "ユーザー名",
          align: "start",
          sortable: false,
          filterable: true,
          groupable: true,
          value: "userName",
        },
        { text: "所属", value: "departmentName", sortable: false },
      ],

      userSelectDialog: false,
      absolute: true,
      overlay: false,
    };
  },
  computed: {
    ...mapGetters(["departmentsList"]),
    usersList() {
      var tmp = [...this.searchResult, ...this.affiliatedUsers]
        .reduce( ( obj, it ) => {
          obj[it.userId] = it;
          return obj;
        }, {} );
      return Object.values( tmp );
    }
  },
  async created() {
    if ( this.affiliatedUsers ) {
      this.selected = this.affiliatedUsers;
    }

    if ( !this.departmentsList ) {
      this.overlay = true
      await this.getDepartments();
      this.overlay = false
    }
  },
  methods: {
    ...mapActions([
      "getDepartments",
    ]),
    selectOK() {
      //選択した値を子から親に値を渡す:emit
      this.userSelectDialog = false;
      this.$emit("selected", this.selected);
    },
    selectCancel() {
      //キャンセル
      this.userSelectDialog = false;
      this.$emit("canceled");
    },
    async search() {
      // 検索
      const params = {};
      if ( this.searchDepartment.length > 0 ) {
        params.departmentIds = this.searchDepartment;
      }
      if ( this.searchUserName ) {
        params.userName = this.searchUserName;
      }
      const UserRepository = RepositoryFactory.get( 'users' );
      const response = await UserRepository.getUserList( params );
      if (response.data.result && response.data.resultCode === '0000') {
        this.searchResult = response.data.data.users;
      } else {
        this._sendError( response.data.message );
      }
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.userSelectDialog = val;
      }
    },
    affiliatedUsers(val) {
      if (val) {
        this.selected = this.affiliatedUsers;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
th {
  background-color: red;
}
</style>
