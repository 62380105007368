<template>
  <div>
    <div class="project-edit py-10 px-8">
      <!-- <common-page-header></common-page-header> -->
      <v-row class="project-edit__row mb-6">
        <v-col class="text-h5 blue--text text--darken-4 px-0 text-center">{{
          pageTitle
        }}</v-col>
      </v-row>
      <project-entry-form
        class="project-edit__row project-edit__row--contents mb-12"
        @entry="entry"
        :value="projectData"
      ></project-entry-form>
    </div>
    <v-dialog v-model="dialog" persistent max-width="450">
      <v-card>
        <v-card-title class="headline grey lighten-2 pa-4"
          >プロジェクト{{ entryLabel }}完了</v-card-title
        >
        <v-card-text class="pa-6"
          >プロジェクトの{{ entryLabel }}を完了しました。</v-card-text
        >
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="btCancel"
            class="project-edit__btn white--text mx-2 mb-3"
            @click="dialog = false"
            >閉じる
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :absolute="absolute" :value="overlay">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { RepositoryFactory } from "@/api/RepositoryFactory";
import CommonPageHeader from "@/components/atomic/organisms/CommonPageHeader.vue";
import ProjectEntryForm from "@/components/atomic/organisms/ProjectEntryForm.vue";

export default {
  components: {
    CommonPageHeader,
    ProjectEntryForm,
  },
  data() {
    return {
      absolute: true,
      overlay: false,
      dialog: false,
      pid: null,
      projectData: null,
      projectRepository: null,
      viewProjectField: [
        "brandStatus",
        "designStatus",
        "flameworkStatus",
        "planningStatus",
        "userinsightStatus",
      ],
      editUrl: "",
      strategyUrl: "",
    };
  },
  async created() {
    this.projectRepository = RepositoryFactory.get("project");
    //データ初期化
    if (this.$route.params.projectId) {
      if (this.project) {
        if (this.project.id != this.$route.params.projectId) {
          this.$store.commit("project", null);
        } else {
          this.setProjectData();
        }
      }
      if (this.projectData == null) {
        this.overlay = true;
        // project取得
        const response = await this.projectRepository.getProject(
          this.$route.params.projectId
        );
        if (response.data.result && response.data.resultCode === "0000") {
          // データがない場合
          if (response.data.errorCode == "MSGCO-BTAPI-0200") {
            this.$router.push("search");
          } else {
            this.overlay = false;
            this.$store.commit("project", response.data.data.project);
            this.setProjectData();
          }
        } else {
          this._sendError(response.data.message);
        }
      }
    }
  },
  computed: {
    ...mapGetters(["project", "userInfo"]),
    pageTitle() {
      return this.projectData ? "プロジェクト編集" : "プロジェクト新規登録";
    },
    entryLabel() {
      return this.projectData ? "編集" : "登録";
    },
  },
  methods: {
    async entry(data) {
      if (
        !data.projectName ||
        !data.status ||
        data.affiliatedUser.length == 0
      ) {
        this.$store.commit("setToast", {
          msg: "プロジェクト名、ステータス、PJ関係者は必須項目です。入力内容を確認ください。",
          color: "red",
          timeout: 4000,
        });
        return;
      }
      // 更新
      if (this.$route.params.projectId) {
        // 表示用フィールド
        for (let key in this.viewProjectField) {
          delete data[this.viewProjectField[key]];
        }
        if (data.salesforceUrl && !data.salesforceClientName) {
          delete data.salesforceUrl;
        }
        const response = await this.projectRepository.update(this.project.id, {
          project: data,
          userId: this.userInfo.userId,
        });
        if (response.data.result && response.data.resultCode === "0000") {
          this.overlay = false;
          this.dialog = true;
          this.$store.commit("project", response.data.data.project);
        } else {
          this._sendError(response.data.message);
        }
        // 登録
      } else {
        const response = await this.projectRepository.register({
          project: data,
          userId: this.userInfo.userId,
        });
        if (response.data.result && response.data.resultCode === "0000") {
          this.overlay = false;
          this.dialog = true;
          this.$store.commit("project", response.data.data.project);
        } else {
          this._sendError(response.data.message);
        }
      }

      this.editUrl = "edit?p=" + this.project.id;
      this.strategyUrl = "strategyold?p=" + this.project.id;
    },
    nextStep() {
      this.dialog = false;
    },
    setProjectData() {
      this.projectData = {};
      this.projectData.status = this.project.status;
      this.projectData.projectName = this.project.projectName;
      this.projectData.projectIconUrl = this.project.projectIconUrl;
      this.projectData.clientName = this.project.clientName;
      this.projectData.businessName = this.project.businessName;
      this.projectData.industry = this.project.industry;
      this.projectData.businessPhase = this.project.businessPhase;
      this.projectData.siteUrl = this.project.siteUrl;
      this.projectData.analyticsUrl = this.project.analyticsUrl;
      this.projectData.salesforceUrl = this.project.salesforceUrl;
      this.projectData.issue = this.project.issue;
      this.projectData.affiliatedUser = this.project.affiliatedUser;
      this.projectData.snsAccount = this.project.snsAccount;
      // 表示用フィールド
      for (let key in this.viewProjectField) {
        this.projectData[this.viewProjectField[key]] =
          this.project[this.viewProjectField[key]];
      }
      //  以下salesforce
      this.projectData.salesforceClientName = this.project.salesforceClientName;
      this.projectData.salesforceTradeName = this.project.salesforceTradeName;
      this.projectData.salesforceCustomerCode =
        this.project.salesforceCustomerCode;
      this.projectData.salesforceRegion = this.project.salesforceRegion;
      this.projectData.salesforceAddress1 = this.project.salesforceAddress1;
      this.projectData.salesforceAddress2 = this.project.salesforceAddress2;
      this.projectData.salesforceWebsite = this.project.salesforceWebsite;
      this.projectData.salesforceSales = this.project.salesforceSales;
      this.projectData.salesforceEmployeeNumType =
        this.project.salesforceEmployeeNumType;
      this.projectData.salesforceBtIndustryClassification =
        this.project.salesforceBtIndustryClassification;
      this.projectData.salesforceBusinessCategoryName1 =
        this.project.salesforceBusinessCategoryName1;
      this.projectData.salesforceBusinessCategoryName2 =
        this.project.salesforceBusinessCategoryName2;
      this.projectData.salesforceLbcStory = this.project.salesforceLbcStory;
      this.projectData.salesforceLbcBestIndustry =
        this.project.salesforceLbcBestIndustry;
    },
  },
};
</script>
<style lang="scss" scoped>
.project-edit {
  background-color: #f2f8ff;

  &__row {
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;

    &--contents {
      padding-bottom: 60px;
    }
  }

  &__btn {
    width: 190px;
  }
}
</style>
